<template>
  <!--    Folder Form-->
  <validation-observer
    ref="fileForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`Upload Files (${folderName})`"
      :show="fileModalOpened"
      :show-overlay="formLoading"
      size="lg"
      @onClose="closeFolderModal()"
    >
      <error-display :error="error" />
      <div
        v-for="(addedFile, index) in fileList"
        :key="index"
        class="row mb-2"
      >
        <div class="col-md-4">
          <lenon-select
            v-model="addedFile.type"
            name="file type"
            label="File Type"
            rules="required"
            :options="fileTypes"
            @input="e=>typeChanged(e,index)"
          />
        </div>

        <div class="col-md-4">
          <lenon-input
            v-model="addedFile.name"
            name="file name"
            label="File Name"
            rules="required"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="addedFile.received_from"
            name="received from"
            label="Received From"
          />
        </div>
        <div class="col-md-4">
          <lenon-date-picker
            :id="`date_received_${index}`"
            v-model="addedFile.date_received"
            name="date_received"
            label="Date Received"
            rules="required"
          />
        </div>
        <div class="col-md-8">
          <lenon-input
            v-model="addedFile.description"
            name="description"
            label="Short Description"
          />
        </div>
        <div class="col-md-4">
          <lenon-file-button
            :disabled="!addedFile.type"
            variant="outline-primary"
            label="Upload"
            class="mr-1"
            :accept="addedFile.extension"
            @onFileUpload="
              e => {
                uploadFile(e, index);
              }
            "
          />
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            icon-only
            @onClick="removeFile(index)"
          />
        </div>
        <div
          v-if="addedFile.file"
          class="col-md-8"
        >
          <b-badge variant="light-primary">
            <feather-icon icon="CheckIcon" />
            {{ addedFile.file.name }}
          </b-badge>
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            icon-only
            @onClick="removeUploadedFile(index)"
          />
        </div>
      </div>
      <div class="row float-right">
        <div class="col-12">
          <lenon-button
            v-if="fileList.length>0"
            name="clear"
            variant="outline-danger"
            icon="XIcon"
            label="Clear Form"
            class="mr-1 mb-1"
            @onClick="clearForm()"
          />
          <lenon-button
            name="add"
            variant="outline-primary"
            icon="PlusIcon"
            label="Add File"
            class="mb-1"
            @onClick="addFile()"
          />
        </div>
      </div>
      <div class="mt-2" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeFolderModal()"
          />
          <lenon-button
            name="save_file"
            label="Upload Files"
            :disabled="invalid || fileList.length<1"
            :loading="fileLoading"
            loading-text="Loading..."
            @onClick="uploadFiles()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonFileButton from '@/lenon/components/LenonFileButton.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

const file = {
  type: null,
  name: null,
  description: null,
  file: null,
  received_from: null,
  date_received: null,
  extension: null,
}
export default {
  name: 'FolderSetup',
  components: {
    LenonFileButton,
    LenonDatePicker,
    LenonSelect,
    ErrorDisplay,
    LenonModal,
    LenonButton,
    LenonInput,
    ValidationObserver,
    BRow,
    BBadge,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    folderName: {
      type: String,
      default: '',
    },
    folderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      fileModalOpened: false,
      updateFolderMode: false,
      fileLoading: false,
      formLoading: false,
      selectedFolderId: null,
      file: { ...file },
      fileTypes: ['Docx', 'Ppt', 'Excel', 'Pdf'],
      fileList: [],
    }
  },
  watch: {
    modalOpened(opened) {
      this.fileModalOpened = opened
    },
  },
  methods: {
    clearForm() {
      this.fileList = []
    },
    closeFolderModal() {
      this.fileModalOpened = false
      this.$emit('modalClosed')
    },
    uploadFiles() {
      this.error = {}
      this.fileLoading = true
      const data = new FormData()
      this.fileList.forEach(item => {
        data.append('name[]', item.name)
        data.append('type[]', item.type)
        data.append('description[]', item.description || '')
        data.append('file[]', item.file)
        data.append('received_from[]', item.received_from)
        data.append('date_received[]', item.date_received)
      })
      this.$store.dispatch('documents/uploadFiles', { data, id: this.folderId })
        .then(res => {
          this.showSuccess('Uploaded files successfully')
          this.clearForm()
          this.$emit("onFilesUploaded",res)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to upload files')
        }).finally(() => {
          this.fileLoading = false
        })
    },
    typeChanged(e, index) {
      this.fileList[index].file = null
      if (this.fileList[index].type === 'Docx') {
        this.fileList[index].extension = '.docx'
      }
      if (this.fileList[index].type === 'Ppt') {
        this.fileList[index].extension = '.pptx'
      }
      if (this.fileList[index].type === 'Excel') {
        this.fileList[index].extension = '.xlsx'
      }
      if (this.fileList[index].type === 'Pdf') {
        this.fileList[index].extension = '.pdf'
      }
    },
    uploadFile(e, index) {
      this.fileList[index].file = e.file
      this.fileList[index].name = e.file.name
    },
    addFile() {
      this.fileList.push({ ...file })
    },
    removeFile(index) {
      this.fileList.splice(index, 1)
    },
    removeUploadedFile(index) {
      this.fileList[index].file = null
      this.fileList[index].validate_file = ''
    },
  },
}
</script>
