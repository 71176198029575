<template>
  <div>
    <b-overlay
      :show="loading"
      :variant="darkMode?'dark':'white'"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <b-card>
        <template #header>
          <div  v-responsive="$store.getters['app/largeScreen']" class="full-width d-flex justify-content-between">
            <div
              class="d-flex flex-column"
            >
              <div
                v-if="currentFolder"
                class="d-flex align-items-center"
              >
                <lenon-button
                  v-if="currentFolder"
                  class="mr-1"
                  label="Back"
                  icon="ArrowLeftIcon"
                  variant="flat-primary"
                  :loading="goingBack"
                  @onClick="backToFolders()"
                />
                <h6 style="padding: 0!important;margin: 0!important;">
                  Files in <b class="text-primary">{{ currentFolder.name }}</b>
                </h6>
              </div>
              <h6
                v-else
                class="mb-0"
              >
                Folders
              </h6>
              <div
                v-if="files.length"
                class="d-flex align-items-center"
              >
                <lenon-input
                  v-model="searchTerm"
                  :placeholder="currentFolder?`Search in ${currentFolder.name}...`:'Search Folders...'"
                  suffix-icon="SearchIcon"
                  @input="searchFiles"
                />
                <lenon-select
                  v-model="sortColumn"
                  class="ml-1 mt-2"
                  placeholder="Sort by"
                  value-name="id"
                  label-name="label"
                  :options="[{id:'date_created',label:'Date Created'},{id:'name',label:'Name'},{id:'date_modified',label:'Date Modified'}]"
                />
                <lenon-select
                  v-model="sortDirection"
                  class="ml-1 mt-2"
                  placeholder="Direction"
                  value-name="id"
                  label-name="label"
                  :options="[{id:'asc',label:'Ascending'},{id:'desc',label:'Descending'}]"
                />
              </div>
            </div>
            <div>
              <div class="d-flex">
                <lenon-button
                  v-if="multiSelectModeEnabled"
                  class="mr-1"
                  :label="`Delete (${selectedItems.length})`"
                  icon="TrashIcon"
                  variant="flat-danger"
                  tool-tip-text="Delete selected files/folders"
                  @onClick="showDeleteModal()"
                />
                <lenon-button
                  v-if="multiSelectModeEnabled && currentFolder"
                  class="mr-1"
                  :label="`Share (${selectedItems.length})`"
                  icon="Share2Icon"
                  variant="flat-warning"
                  tool-tip-text="Share selected files/folders"
                  @onClick="showShareSetupModal()"
                />
                <lenon-button
                  v-if="!currentFolder"
                  class=""
                  label="Folder"
                  icon="PlusIcon"
                  variant="flat-primary"
                  tool-tip-text="Create a new folder"
                  @onClick="showFolderSetupModal()"
                />
                <lenon-button
                  v-if="currentFolder"
                  class="mr-1"
                  label="Upload"
                  icon="UploadCloudIcon"
                  variant="flat-primary"
                  :tool-tip-text="`Upload files to ${currentFolder.name}`"
                  @onClick="showUploadFileModal()"
                />
                <lenon-button
                  v-if="currentFolder"
                  class=""
                  label="Notes"
                  icon="PlusIcon"
                  variant="flat-primary"
                  :tool-tip-text="`Create notes and save to ${currentFolder.name}.`"
                  @onClick="showNotesModal()"
                />
              </div>
            </div>
          </div>
          <div  v-responsive="$store.getters['app/smallScreen']" class="row">
            <div
                class="col-md-6"
            >
              <div
                  v-if="currentFolder"
                  class="d-flex align-items-center"
              >
                <lenon-button
                    v-if="currentFolder"
                    class="mr-1"
                    label="Back"
                    icon="ArrowLeftIcon"
                    variant="flat-primary"
                    :loading="goingBack"
                    @onClick="backToFolders()"
                />
                <h6 style="padding: 0!important;margin: 0!important;">
                  Files in <b class="text-primary">{{ currentFolder.name }}</b>
                </h6>
              </div>
              <h6
                  v-else
                  class="mb-0"
              >
                Folders
              </h6>
            </div>
            <div class="col-md-6">
              <div
                  v-if="files.length"
                  class="row"
              >
                <div class="col-md-6">
                  <lenon-input
                      v-model="searchTerm"
                      :placeholder="currentFolder?`Search in ${currentFolder.name}...`:'Search Folders...'"
                      suffix-icon="SearchIcon"
                      @input="searchFiles"
                  />
                </div>
                <div class="col-md-6">
                  <lenon-select
                      v-model="sortColumn"
                      class="ml-1 mt-2"
                      placeholder="Sort by"
                      value-name="id"
                      label-name="label"
                      :options="[{id:'date_created',label:'Date Created'},{id:'name',label:'Name'},{id:'date_modified',label:'Date Modified'}]"
                  />
                </div>
                <div class="col-md-6">
                  <lenon-select
                      v-model="sortDirection"
                      class="ml-1 mt-2"
                      placeholder="Direction"
                      value-name="id"
                      label-name="label"
                      :options="[{id:'asc',label:'Ascending'},{id:'desc',label:'Descending'}]"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
               <div class="col-md-4"  v-if="multiSelectModeEnabled">
                 <lenon-button
                     class="mr-1"
                     :label="`Delete (${selectedItems.length})`"
                     icon="TrashIcon"
                     variant="flat-danger"
                     tool-tip-text="Delete selected files/folders"
                     @onClick="showDeleteModal()"
                 />
               </div>
               <div class="col-md-4"  v-if="multiSelectModeEnabled && currentFolder">
                 <lenon-button
                     class="mr-1"
                     :label="`Share (${selectedItems.length})`"
                     icon="Share2Icon"
                     variant="flat-warning"
                     tool-tip-text="Share selected files/folders"
                     @onClick="showShareSetupModal()"
                 />
               </div>
                <div class="col-md-4" v-if="!currentFolder">
                  <lenon-button
                      class=""
                      label="Folder"
                      icon="PlusIcon"
                      variant="flat-primary"
                      tool-tip-text="Create a new folder"
                      @onClick="showFolderSetupModal()"
                  />
                </div>
                <div class="col-md-4" v-if="currentFolder">
                  <lenon-button
                      class="mr-1"
                      label="Upload"
                      icon="UploadCloudIcon"
                      variant="flat-primary"
                      :tool-tip-text="`Upload files to ${currentFolder.name}`"
                      @onClick="showUploadFileModal()"
                  />
                </div>
                <div class="col-md-4"  v-if="currentFolder">
                  <lenon-button
                      class=""
                      label="Notes"
                      icon="PlusIcon"
                      variant="flat-primary"
                      :tool-tip-text="`Create notes and save to ${currentFolder.name}.`"
                      @onClick="showNotesModal()"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="files.length"
          class="d-flex flex-wrap"
        >
          <lenon-file
            v-for="(item,i) in filteredFiles"
            :key="i"
            :disabled="disabled"
            :item="item"
            @optionSelected="(type)=>handleOption(type,item)"
            @dbclick="item.type==='Folder'?openFolder(item):openFile(item)"
            @click="(e)=>handleClick(i,e)"
            @rightclick="(e)=>handleRightClick(i,e)"
          />
        </div>
        <div
          v-else
        >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <font-awesome-icon
              icon="folder-open"
              size="3x"
              :style="{ color: '#7a7a7a' }"
              style="margin-bottom: 5px;"
            />
            <strong>No Items</strong>
          </div>

        </div>
      </b-card>
    </b-overlay>
    <folder-setup
      :folder-id="currentFolder?currentFolder.id:null"
      :details="selectedItem"
      :modal-opened="folderModalOpened"
      @modalClosed="closeModal('folders')"
    />
    <share-file
      :files="selectedItems"
      :modal-opened="shareModalOpened"
      @modalClosed="closeModal('share')"
    />
    <lenon-modal
      :title="`Delete (${selectedItems.length}) Items`"
      :show="deleteModalOpened"
      ok-text="Delete"
      :loading="deletingFiles"
      @onOk="deleteFiles()"
      @onClose="closeModal('delete')"
    >
      Selected Items will be temporarily moved to Trash Store, check the box below to delete permanently.
      <b-form-checkbox
        v-model="deletePermanently"
        class="mt-1"
      >
        Delete Permanently
      </b-form-checkbox>
    </lenon-modal>
    <lenon-modal
      title="Create Notes"
      :show="notesModalOpened"
      ok-text="Create"
      :ok-disabled="!noteContent"
      size="lg"
      :loading="creatingNotes"
      @onClose="closeModal('notes')"
      @onOk="createNotes()"
    >
      <lenon-input
        v-model="noteName"
        label="File Name"
        name="name"
        rules="required"
      />
      <lenon-rich-text
        v-model="noteContent"
        name="content"
        label="Content"
        placeholder="Enter Some Content..."
        rules="required"
      />
    </lenon-modal>
    <upload-file
      :folder-name="selectedItem?selectedItem.name:''"
      :folder-id="selectedItem?selectedItem.id:null"
      :modal-opened="uploadFilesModalOpened"
      @modalClosed="closeModal('upload')"
      @onFilesUploaded="handleFilesUploaded"
    />
  </div>
</template>

<script>
import { BCard, BFormCheckbox, BOverlay } from 'bootstrap-vue'
import LenonFile from '@/views/documents/folders/File.vue'
import FolderSetup from '@/views/documents/folders/FolderSetup.vue'
import ShareFile from '@/views/documents/folders/ShareFile.vue'
import UploadFile from '@/views/documents/folders/UploadFile.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonRichText from '@/lenon/components/LenonRichText.vue'

const { skin } = useAppConfig()
export default {
  name: 'Index',
  components: {
    UploadFile,
    LenonRichText,
    LenonModal,
    ShareFile,
    FolderSetup,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonFile,
    BCard,
    BFormCheckbox,
    BOverlay,
  },
  mixins: [showToast],
  data() {
    return {
      searchTerm: null,
      loading: false,
      goingBack: false,
      deletingFiles: false,
      disabled: false,
      currentFolder: null,
      noteName: null,
      creatingNotes: false,
      folderModalOpened: false,
      shareModalOpened: false,
      deleteModalOpened: false,
      deletePermanently: false,
      selectedItem: null,
      notesModalOpened: false,
      uploadFilesModalOpened: false,
      noteContent: null,
      filteredFiles: [],
      sortColumn: null,
      sortDirection: null,
    }
  },
  computed: {
    documentSettings() {
      return this.$store.getters['documents/settings']
    },
    files() {
      return this.$store.getters['documents/files']
    },
    multiSelectModeEnabled() {
      return this.selectedItems.length > 1
    },
    selectedItems() {
      return this.filteredFiles.filter(item => item.selected)
    },
    darkMode() {
      return skin.value === 'dark'
    },
  },
  watch: {
    files(val) {
      this.filteredFiles = [...val]
      this.sortFiles(this.sortColumn || 'date_created', this.sortDirection || 'desc')
    },
    sortColumn(col) {
      this.sortFiles(col || 'date_created', this.sortDirection || 'desc')
      this.$store.commit('documents/setSettings', { sortColumn: col })
    },
    sortDirection(dir) {
      this.sortFiles(this.sortColumn || 'date_created', dir || 'desc')
      this.$store.commit('documents/setSettings', { sortDirection: dir })
    },
    currentFolder(val) {
      this.$store.commit('documents/setSettings', { currentFolder: val })
    },
  },
  mounted() {
    this.sortColumn = this.documentSettings.sortColumn
    this.sortDirection = this.documentSettings.sortDirection
    this.currentFolder = this.documentSettings.currentFolder
    this.fetchFiles()
  },
  methods: {
    fetchFiles() {
      this.loading = true
      this.$store.dispatch('documents/fetchFiles', this.currentFolder ? this.currentFolder.id : null).finally(() => {
        this.loading = false
      })
    },
    clearSelected() {
      this.filteredFiles = this.filteredFiles.map(item => ({
        ...item, selected: false,
      }))
    },
    handleOption(type, item) {
      this.selectedItem = { ...item }
      if (type === 'edit-folder') {
        this.showFolderSetupModal()
      }
      if (type === 'upload') {
        this.uploadFilesModalOpened = true
      }
      if (type === 'share') {
        this.showShareSetupModal()
      }
      if (type === 'edit-file') {
        this.showFolderSetupModal()
      }
      if (type === 'delete') {
        this.showDeleteModal()
      }
    },
    handleClick(index, e) {
      if (!e.ctrlKey) {
        this.filteredFiles = this.filteredFiles.map(item => ({
          ...item, selected: false,
        }))
      }
      this.filteredFiles[index].selected = !this.filteredFiles[index].selected
    },
    handleRightClick(index, e) {
      this.filteredFiles = this.filteredFiles.map(item => ({
        ...item, selected: false,
      }))
      this.filteredFiles[index].selected = true
    },
    sortFiles(col, dir) {
      this.filteredFiles.sort((a, b) => {
        if (dir === 'asc') {
          if (col !== 'name') {
            return new Date(a[col]) - new Date(b[col])
          }
          return a[col].localeCompare(b[col])
        }
        if (col !== 'name') {
          return new Date(b[col]) - new Date(a[col])
        }
        return b[col].localeCompare(a[col])
      })
    },
    searchFiles(search) {
      this.filteredFiles = this.files.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
      this.sortFiles(this.sortColumn || 'date_created', this.sortDirection || 'desc')
    },
    openFile(item) {
      const url = `${process.env.VUE_APP_PREVIEW_URL}?name=${item.name}&path=${process.env.VUE_APP_STORAGE_PATH}/${item.path}`
      window.open(url, '_blank')
    },
    openFolder(itm) {
      const item = itm
      this.disabled = true
      item.loading = true
      this.$store.dispatch('documents/fetchFiles', item.id).finally(() => {
        this.currentFolder = item
        item.loading = false
        this.disabled = false
      })
    },
    backToFolders() {
      this.goingBack = true
      this.$store.dispatch('documents/fetchFiles', null).finally(() => {
        this.currentFolder = null
        this.disabled = false
        this.goingBack = false
        this.searchTerm = null
      })
    },
    deleteFiles() {
      this.deletingFiles = true
      this.$store.dispatch('documents/deleteFiles',
        { permanent: this.deletePermanently, ids: this.selectedItems.map(it => it.id) })
        .then(() => {
          this.selectedItem = null
          this.showSuccess('Files deleted successfully')
          this.closeModal('delete')
        }).catch(() => {
          this.showError('Failed to delete files')
        })
        .finally(() => {
          this.deletingFiles = false
        })
    },
    createNotes() {
      this.creatingNotes = true
      const notes = `<body>${this.noteContent.trim().replaceAll('<br>', '')}</body>`
      this.$store.dispatch('documents/createNotes', { content: notes, name: this.noteName, folder_id: this.currentFolder.id })
        .then(() => {
          this.noteContent = null
          this.noteName = null
          this.showSuccess('Note created successfully')
          this.notesModalOpened = false
        }).catch(() => {
          this.showError('Failed to create note')
        })
        .finally(() => {
          this.creatingNotes = false
        })
    },
    handleFilesUploaded(data){
      if(this.currentFolder){
        this.$store.commit("documents/addFiles",data)
      }else{
        const folder = {...this.selectedItem}
        let size = +folder.size.toString().replaceAll("MB","").trim()
        data.forEach((f)=>{
          folder.total_files++
          size += (+f.size.toString().replaceAll("MB","").trim())
        })
        folder.size = size.toFixed(3)
        this.$store.commit("documents/updateFile",folder)
      }
      // const folder = {...
      //   this.selectedItem
      // }
      // data.forEach((d)=>{
      //
      // })
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'folders':
          this.folderModalOpened = false
          this.selectedItem = null
          break
        case 'share':
          this.shareModalOpened = false
          break
        case 'delete':
          this.deleteModalOpened = false
          break
        case 'notes':
          this.notesModalOpened = false
          break
        case 'upload':
          this.uploadFilesModalOpened = false
          break
      }
    },
    showNotesModal() {
      this.notesModalOpened = true
    },
    showFolderSetupModal() {
      this.folderModalOpened = true
    },
    showShareSetupModal() {
      this.shareModalOpened = true
    },
    showDeleteModal() {
      if (this.selectedItems.length) {
        this.deleteModalOpened = true
      } else {
        this.showInfo('Make sure at least one item is highlighted/selected')
      }
    },
    showUploadFileModal() {
      this.selectedItem = { ...this.currentFolder }
      this.uploadFilesModalOpened = true
    },
  },
}
</script>

<style scoped>

</style>
<!--[-->
<!--{-->
<!--id: 1,-->
<!--name: 'Adobe',-->
<!--file_type: 'Folder',-->
<!--total_files: 32,-->
<!--size: '23MB',-->
<!--loading: false,-->
<!--selected: false,-->
<!--date_created: null,-->
<!--date_modified: null,-->
<!--},-->
<!--{-->
<!--name: 'Hardworking Tech',-->
<!--file_type: 'Folder',-->
<!--total_files: 23,-->
<!--size: '23MB',-->
<!--loading: false,-->
<!--selected: false,-->
<!--date_created: null,-->
<!--date_modified: null,-->
<!--},-->
<!--{-->
<!--name: 'Our Heritage',-->
<!--file_type: 'Folder',-->
<!--total_files: 18,-->
<!--size: '200MB',-->
<!--loading: false,-->
<!--selected: false,-->
<!--date_created: null,-->
<!--date_modified: null,-->
<!--},-->
<!--{-->
<!--name: 'Last Defence',-->
<!--file_type: 'Folder',-->
<!--total_files: 4,-->
<!--size: '3MB',-->
<!--loading: false,-->
<!--selected: false,-->
<!--date_created: null,-->
<!--date_modified: null,-->
<!--},-->
<!--]-->
