<template>
  <!--    Folder Form-->
  <validation-observer
    ref="folderForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateFolderMode?'Update':'Create'} ${folder.type==='Folder'?'Folder':'File'}`"
      :show="folderModalOpened"
      :show-overlay="formLoading"
      size="md"
      @onClose="closeFolderModal()"
    >
      <error-display :error="error" />
      <lenon-input
        v-model="folder.name"
        name="name"
        :label="`${folder.type==='Folder'?'Folder':'File'} Name`"
        rules="required"
      />
      <lenon-text-area
        v-model="folder.description"
        name="description"
        label="Description"
        placeholder="Enter description"
      />
      <div class="mt-2" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeFolderModal()"
          />
          <lenon-button
            name="save_folder"
            :label="updateFolderMode?'Update':'Create'"
            :disabled="invalid"
            :loading="folderLoading"
            loading-text="Loading..."
            @onClick="updateFolderMode?updateFolder():createFolder()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import LenonInput from '@/lenon/components/LenonInput.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

const fold = {
  id: null,
  name: null,
  description: null,
  type: 'Folder',
}
export default {
  name: 'FolderSetup',
  components: {
    LenonTextArea,
    ErrorDisplay,
    LenonModal,
    LenonButton,
    LenonInput,
    ValidationObserver,
    BRow,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: Number,
      default: null,
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: {},
      folderModalOpened: false,
      updateFolderMode: false,
      folderLoading: false,
      formLoading: false,
      selectedFolderId: null,
      folder: { ...fold },
    }
  },
  computed: {
    // selectedFolder() {
    //   const folder = this.folders.find(o => o.id === this.selectedFolderId)
    //   if (!folder) {
    //     return null
    //   }
    //   return {
    //     ...folder,
    //   }
    // },
  },
  watch: {
    modalOpened(opened) {
      this.folderModalOpened = opened
    },
    details(details) {
      this.updateFolderMode = !!details
      this.folder = details || fold
    },
  },
  methods: {
    resetFolderForm() {
      this.folder = { ...fold }
      this.selectedFolderId = null
      this.updateFolderMode = false
      this.$refs.folderForm.reset()
    },
    closeFolderModal() {
      this.folderModalOpened = false
      this.$emit('modalClosed')
    },
    createFolder() {
      this.error = {}
      this.folderLoading = true
      this.$store.dispatch('documents/createFile', { file: this.folder, folderId: this.folderId })
        .then(res => {
          this.showSuccess('Created folder successfully')
          this.resetFolderForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create folder')
        }).finally(() => {
          this.folderLoading = false
        })
    },
    updateFolder() {
      this.error = {}
      if (!this.folder.id) {
        return
      }
      this.folderLoading = true
      this.$store.dispatch('documents/updateFile', this.folder)
        .then(res => {
          this.showSuccess('Updated successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Update failed')
        }).finally(() => {
          this.folderLoading = false
        })
    },
  },
}
</script>
