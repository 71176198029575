<template>
  <!--    Contribution Form-->
  <validation-observer
    ref="shareForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      title="Share Documents"
      :show="shareModalOpened"
      :show-overlay="formLoading"
      size="md"
      @onClose="closeShareModal()"
    >
      <error-display :error="error" />
      <div class="row mb-1">
        <div class="col-12">
          <strong>Files to Share</strong>
        </div>
        <div
          v-for="(file,index) in files"
          :key="index"
          class="col-12"
        >
          <b-badge variant="light-primary">
            <feather-icon icon="CheckIcon" />
            {{ file.name }} - {{ file.type }} ({{ file.size }}MB)
          </b-badge>
        </div>
      </div>
      <div>
        <b-form-checkbox
          v-model="shareWithAll"
          class="mb-1"
        >
          Share With All Users
        </b-form-checkbox>
        <lenon-input
          v-model="shareSubject"
          label="Subject"
          rules="required"
          name="subject"
        />
        <lenon-rich-text
          v-model="shareMessage"
          label="Message"
          placeholder="Enter some message..."
          name="message"
          rules="required"
          class="mb-1"
        />
        <lenon-input
          v-if="!shareWithAll"
          v-model="searchTerm"
          name="contributor"
          label="Search Recipients"
          placeholder="Search Users By Name"
          suffix-icon="SearchIcon"
          @onSuffixIconClick="getRecipientDetails"
          @onEnterPressed="getRecipientDetails"
        />
      </div>
      <div
        v-if="!shareWithAll"
        class="row"
      >
        <div
          v-for="(reci,i) in recipients"
          :key="i"
          class="col-12"
        >
          <div
            class="d-flex align-items-center"
          >
            <lenon-profile-photo
              size="30"
              :photo="reci.photo"
            />
            <div style="margin-right: 5px;" />
            <b-badge variant="light-primary">
              {{ reci.name }}
            </b-badge>
            <lenon-button
              icon-only
              variant="flat-danger"
              icon="XIcon"
              @onClick="clearContributor(i)"
            />
          </div>
        </div>
      </div>
      <lenon-button
        label="Clear Form"
        icon="XIcon"
        variant="flat-danger"
        @onClick="clearForm()"
      />
      <div class="mt-2" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeShareModal()"
          />
          <lenon-button
            name="save_share"
            label="Share"
            :disabled="invalid || (!shareWithAll && recipients.length<1)"
            :loading="shareLoading"
            loading-text="Loading..."
            @onClick="shareFiles()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import showToast from '@/lenon/mixins/showToast'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto.vue'
import LenonRichText from '@/lenon/components/LenonRichText.vue'

export default {
  name: 'ShareFile',
  components: {
    LenonRichText,
    LenonProfilePhoto,
    ErrorDisplay,
    LenonModal,
    LenonButton,
    LenonSelect,
    LenonInput,
    ValidationObserver,
    BRow,
    BBadge,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: {},
      shareModalOpened: false,
      shareLoading: false,
      shareWithAll: false,
      formLoading: false,
      recipientType: null,
      searchTerm: null,
      shareMessage: null,
      shareSubject: null,
      recipients: [],
      shareWith: null,
      shareTitle: null,
      shareOptions: [{ id: 'local', label: 'Members & Farmer Groups' }, { id: 'public', label: 'Social Platforms' }],
      networks: [
        {
          network: 'linkedin',
          name: 'LinkedIn',
          icon: 'linkedin',
          color: '#007bb5',
        },
        {
          network: 'twitter',
          name: 'Twitter',
          icon: 'twitter',
          color: '#1da1f2',
        },
        {
          network: 'whatsapp',
          name: 'Whatsapp',
          icon: 'whatsapp',
          color: '#25d366',
        },
        {
          network: 'email',
          name: 'Email',
          icon: 'google-plus',
          color: '#de1d0b',
        },
        {
          network: 'facebook',
          name: 'Facebook',
          icon: 'facebook',
          color: '#1877f2',
        },
      ],
    }
  },
  computed: {

  },
  watch: {
    // shareWithAll(val) {
    //   if (val) {
    //     this.recipients = []
    //   }
    // },
    files(val) {
      if (val.length > 1) {
        this.shareWith = 'local'
      }
    },
    modalOpened(opened) {
      this.shareModalOpened = opened
    },
    id(id) {
      this.selectedContributionId = id
    },
  },
  methods: {
    clearForm() {
      this.shareWithAll = false
      this.shareTitle = null
      this.shareMessage = null
      this.shareSubject = null
      this.searchTerm = null
      this.recipients = []
      this.$refs.shareForm.reset()
    },
    addRecipient(data) {
      const present = this.recipients.some(r => r.id === data.id)
      if (!present) {
        this.recipients.push(data)
      }
    },
    getPath(path) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${path}`
    },
    clearContributor(i) {
      this.recipients.splice(i, 1)
    },
    closeShareModal() {
      this.shareModalOpened = false
      this.$emit('modalClosed')
    },
    getRecipientDetails(search) {
      if (search) {
        this.formLoading = true
        this.$store.dispatch('documents/getUser', search).then(res => {
          this.addRecipient(res)
        }).catch(err => {
          this.error = err
        }).finally(() => {
          this.formLoading = false
          this.searchTerm = null
        })
      } else {
        this.showInfo('Enter User Name')
      }
    },
    shareFiles() {
      this.error = {}
      if (this.shareWithAll) {
        this.recipients = []
      }
      this.shareLoading = true
      this.$store.dispatch('documents/shareFiles',
        {
          ids: this.recipients.map(rc => rc.id),
          subject: this.shareSubject,
          message: this.shareMessage,
          share_with: this.recipientType,
          share_with_all: this.shareWithAll,
          files: this.files.map(f => ({ name: f.name, path: f.path })),
        })
        .then(res => {
          this.showSuccess('Documents shared successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to share documents')
        }).finally(() => {
          this.shareLoading = false
        })
    },
  },
}
</script>
